<template>
  <div id="app" style="position: relative">
    <canvas id="container" style="width: 100%; height: 300px"></canvas>

    <div
      style="
        width: 100%;
        height: 300px;
        position: absolute;
        text-align: center;
        display: flex;
        z-index: 11;
        top: 0.3rem;
        left: 0;
        flex-direction: column;
        pointer-events: none;
        justify-content: center;
        font-size: 0.3rem;
      "
    >
      <div>{{ selectFilm.filmName }}</div>
      <div>
        票房:￥{{ (selectFilm.BoxOffice / 1000).toFixed(2) }}万(含服务费)
      </div>
      <div>
        服务费:￥{{
          (
            (selectFilm.BoxOffice - selectFilm.BoxOfficeNoService) /
            1000
          ).toFixed(2)
        }}万
      </div>
      <div>票房占比:{{ selectFilm.BoxOfficeByRate }}%</div>
      <div>产出比:{{ selectFilm.Roi }}</div>
    </div>
  </div>
</template>

<script>
import F2 from '@antv/f2/lib/index-all'
// import * as F2  from '@antv/f2'
// import { Chart } from '@antv/f2';
export default {
  props: {
    datas: {
      type: Array,
      required: true,
      default() {
        return [{}]
      },
    },
  },
  name: 'App',
  data() {
    return {
      selectFilm: {},
    }
  },

  methods: {
    drawChart() {
      const _this = this
      const data = _this.datas
      console.log(data)
      _this.selectFilm = data[0]
      const chart = new F2.Chart({
        id: 'container',
        pixelRatio: window.devicePixelRatio,
      })

      chart.source(data)
      chart.coord('polar', {
        transposed: true,
        innerRadius: 0.7,
        radius: 0.85,
      })
      chart.axis(false)
      chart.legend(false)
      chart.tooltip(false)
      // chart.guide()
      //   .html({
      //     position: [ '50%', '50%' ],
      //     html: `<div style="text-align: center;width:150px;height: 50px;">\n
      //           <p style="font-size: 12px;color: #999;margin: 0" id="title"></p>\n   ${this.selectFilm.filmName}
      //            <p style="font-size: 18px;color: #343434;margin: 0;font-weight: bold;" id="money"></p>\n      </div>`
      //   });
      // 配置文本饼图
      chart.pieLabel({
        sidePadding: 15,
        activeShape: false,
        label1: function label1(data) {
          return {
            text: data.filmName,
            fill: '#808080',
          }
        },
        // label2: function label2(data) {
        //   return {
        //     fill: '#000000',
        //     text: `${(data.Roi * 100).toFixed(2)}%`,
        //     fontWeight: 500,
        //     fontSize: 10
        //   }
        // },
        onClick: function onClick(ev) {
          const data = ev.data
          if (data) {
            // alert(data.CityLineRoi)
            // console.log(data)
            _this.selectFilm = data
            // console.log(document.getElementById('#title'));

            // chart.render();
          }
        },
      })
      chart
        .interval()
        .position('const*BoxOffice')
        .color('filmName', [
          '#1890FF',
          '#13C2C2',
          '#2FC25B',
          '#FACC14',
          '#F04864',
          '#8543E0',
          '#3436C7',
          '#223273',
          '#342342',
        ])
        .adjust('stack')
      chart.render()

      // 绘制内阴影
      const frontPlot = chart.get('frontPlot')
      const coord = chart.get('coord') // 获取坐标系对象
      frontPlot.addShape('sector', {
        attrs: {
          x: coord.center.x,
          y: coord.center.y,
          r: coord.circleRadius * coord.innerRadius * 1.2, // 全半径
          r0: coord.circleRadius * coord.innerRadius,
          fill: '#000',
          opacity: 0.15,
        },
      })
      chart.get('canvas').draw()
    },
  },
  mounted() {
    var v = this
    // this.$nextTick(() => {
    // 	v.drawChart();
    // });
    v.drawChart()
  },
}
</script>

<style></style>
