<template>
  <div>
    <div class="tab">
      <div class="td">
        <div>影片分组</div>
        <div>院线排片率</div>
        <div>院线排片ROI</div>
        <div>城市平均排片率</div>
        <div>城市影片票房比</div>
      </div>
      <div class="td" v-for="(film, i) in datas" :key="i">
        <div
          @click="
            toPath('/cinemaAssistant/filmArrangementDetail', {
              name: film.FilmName,
              dates: date,
            })
          "
        >
          {{ film.FilmName }}
        </div>
        <div>{{ film.ChainSessionByRate }}%</div>
        <div>{{ film.ChainRoi || '/' }}</div>
        <div>{{ (film.CitySessionByRate || '0') + '%' }}</div>
        <div>{{ (film.CityBoxOfficeByRate || '0') + '%' }}</div>
      </div>
    </div>
    <!-- {{datas}} -->
  </div>
</template>

<script>
export default {
  name: 'list',
  props: {
    date: {
      type: String,
      required: true,
      default: '',
    },
    datas: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
  },
  mounted() {
    // console.log("ADFG",this.datas);
  },
  methods: {
    toPath(path, query) {
      this.$router.push({ path, query })
    },
  },
  data() {
    return {}
  },
}
</script>

<style scoped lang="less">
.tab {
  //border: 1px solid red;
  .td {
    display: flex;
    font-size: 0.36rem;
    text-align: center;
    div {
      flex: 1;
      border-left: 1px solid rgba(36, 123, 178, 0.22);
      height: 1rem;
    }
    div:first-child {
      flex: 1.5;
      border: none;
    }
    &:first-child {
      font-size: 0.36rem;
      color: #4c78a9;
    }
  }
}
</style>
